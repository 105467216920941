<template>
  <div class="agreement_container" ref="list">
    <div class="agreement_main" ref="signPage">
      <h3>{{ title }}</h3>
      <p class="agreement_space_row">
        <span>甲方：</span
        ><span class="agreement_main_line">北京生命绿洲公益服务中心</span>
      </p>
      <!-- <p class='agreement_space_row'><span>注册地址：</span><span class='agreement_main_line'>北京市东城区藏经馆胡同17号1幢1863室</span></p> -->
      <p class="agreement_space_row">
        <span>乙方：</span
        ><span class="agreement_main_line">{{ items.doctor.name }}</span>
      </p>
      <!-- <p class='agreement_space_row'><span>身份证号码：</span><span class='agreement_main_line'>{{items.doctor.id_card_number}}</span></p>
        <p class='agreement_space_row'><span>手机号（必填）</span><span class='agreement_main_line'>{{items.doctor.phone}}</span></p> -->
      <p class="agreement_main_seat"></p>
      <p>
        甲方委托乙方在<span class="agreement_main_line"
          >“ 健康中国行——百展讲堂项目 ”</span
        >
        （以下简称“<span class="agreement_main_bold">本活动</span> ”）中于
        <span class="agreement_main_line">
          <span class="agreement_main_bold"
            >【{{ items.meet.meeting_number }}</span
          >线上互动课堂 <span class="agreement_main_bold"> 】</span></span
        >
        提供劳务。为明确双方义务及权利，经双方协商达成以下协议（以下简称“
        <span class="agreement_main_bold">本协议</span>”）：
      </p>

      <!-- <div class="agreement_table">
        <div class="agreement_row">
          <div class="agreement_td">服务时间</div>
          <div class="agreement_td">
            {{ getText(0) }}年{{ getText(1) }}月{{ getText(2) }}日
          </div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">活动名称</div>
          <div class="agreement_td">{{ items.meet.meeting_name }}</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">服务类别</div>
          <div class="agreement_td">学术会议主持</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">服务内容</div>
          <div class="agreement_td">IO智能云课堂会议主持</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">服务报酬</div>
          <div class="agreement_td">人民币1500元（税后）</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">开户行信息</div>
          <div class="agreement_td">
            {{ items.doctor.bank_name }}{{ items.doctor.bank_city
            }}{{ items.doctor.bank_name_point }}
          </div>
        </div>
      </div> -->
      <h4>1. 权利与义务</h4>
      <p>
        1.1
        乙方应当持有，且在本协议期限内都将持有安全、妥善及合法履行本协议规定劳务内容所需的所有适当的资质、执照和批准。
      </p>
      <p>
        1.2
        乙方陈述并保证：（1）乙方在本协议下提供劳务已取得必要的批准且不会对其正常执业造成影响；（2）乙方签署本协议、提供本协议规定的劳务及收取相应的劳务费在目前和未来均不会导致乙方违反任何法律法规或其受雇的任何机构或实体的任何政策或程序；（3）其具备根据行业通行的专业标准提供本协议项下服务所需的专业知识；（4）其将根据行业惯常的专业标准以专业娴熟的方式履行服务；（5）其将遵守履行服务适用的所有法律、规定、法规和准则；及（6）其将遵守适用于提供附件所述服务的所有法律、规定、法规和准则。
      </p>
      <p>
        1.3
        乙方应当按照本协议约定的条款与条件完成甲方委托的工作内容。基于乙方提供的工作内容，甲方应按照本协议约定在乙方的劳务完成后支付相应的劳务费。
      </p>
      <p>
        1.4
        甲乙双方均应按照中国相关法律法规的规定，履行其各自在本协议下依据法律规定应当履行的与依法纳税相关的责任与义务，包括但不限于由甲方对乙方应缴的个人所得税履行代扣代缴义务，及应纳税人以合法的方式缴纳应缴的增值税。
      </p>
      <p>
        1.5
        乙方保证其对本协议的履行不构成任何利益冲突，乙方就此特别陈述、保证并承诺如下：
      </p>
      <p class="agreement_main_secondary">
        （1）乙方不对任何第三方负有将以任何形式禁止或限制其履行本协议的任何义务，包括但不限于与其受雇的任何机构或实体、政府机关等之间基于合同、适用法律法规和/或政策程序而产生的利益冲突。
        前述“法律法规和/或政策程序”包括但不限于，禁止为获得或维持业务之目的而向政府官员行贿或付款的法律法规、禁止或者限制其履行本协议的相关法律法规政策等；
      </p>
      <p class="agreement_main_secondary">
        （2）乙方对本协议的履行，在目前和未来均不会导致其违反任何法律法规、政府机关规定和/或其受雇的任何机构或实体的任何政策或程序；
      </p>
      <p class="agreement_main_secondary">
        （3）乙方对本协议的履行，已取得所有必要的批准（包括但不限于其受雇的任何机构或实体的同意）且不会对其履行本职工作造成任何影响；且
      </p>
      <p class="agreement_main_secondary">
        （4）乙方在本协议期限及其前后的合理期间内（如法律法规有相关规定，以该规定为准），其并未参与且并未预见可能参与任何涉及本活动支持方【百济神州（上海）生物医药技术有限公司】（“百济神州”）及其关联方（以下统称“百济神州集团”）的政府行为、行动或决策等，包括但不限于，对百济神州集团产品或服务采购、使用或准入相关决策提出意见或建议，或以其他合法方式对该决策流程产生影响的情况。
      </p>
      <p class="agreement_main_secondary">
        （5）乙方同意，如其在本协议期限及其前后的合理期间内（如法律法规有相关规定，以该规定为准），发现可能出现与本条上述第（1）项至第（4）项任何不一致的情形，乙方应当（1）就该等情况立即以书面方式通知甲方；（2）主动向相关机构披露与甲方及百济神州合作的情况；（3）根据相关机构的要求和指引适用回避流程，或者在获得相关机构同意无需回避的前提下，确保不受与甲方、百济神州之间合作的影响，客观、中立的提供意见与判断。
        尽管有前述约定，甲方保留以书面通知方式立即终止就本项目与乙方的合作（如适用）和/或采取其他适当的行动的权利，乙方应予以配合。
      </p>
      <p>
        1.6
        甲方双方一致确认本协议项下的劳务费是合理的，并且反映所提供服务的公平市场价值。劳务费的提供完全不构成开出、供应、管理、推荐、购买、使用或出售任何商业产品的义务或诱导，且不以任何方式构成与商业实体或商业产品相关的任何业务机会或乙方就此作出或未来可能作出其他决定的条件。
      </p>
      <p>
        1.7
        甲乙双方陈述并保证，其将遵守一切有关反贿赂及反腐败（《反贿赂法》）的适用法律、法令、法规和准则，包括但不限于美国《反海外腐败法》。
      </p>
      <p>
        1.8
        甲方不得为以下之目的直接或间接向任何政府官员或《反贿赂法》中规定的其他人员、实体或机构，提供或支付有价值的物品：
      </p>
      <p class="agreement_main_secondary">
        1.8.1 为任何商业实体获得或维持业务；
      </p>
      <p class="agreement_main_secondary">
        1.8.2 不当地影响某一行为或决定而使商业实体获益；和/或
      </p>
      <p class="agreement_main_secondary">1.8.3 为商业实体谋取不当利益。</p>
      <p>
        1.9
        甲乙双方承诺保留准确透明的记录以反映交易与记录。若任何一方有任何理由相信另一方可能违反本条款，应当立即以书面形式告知另一方并与
        另一方协作对该事实情况进行调查和书面记录。
      </p>
      <p>
        1.10
        对第1.5-1.9条的违反视为对本协议的实质性违反，守约方有权立即终止本协议。
      </p>
      <p>
        1.11
        为传播医学知识或学术交流、或帮助医学教育次发达地区跟进医学发展之目的，甲方或其指定的第三方可能对乙方在本协议下提供服务的过程进行直播和/或录音录像拍照，并对乙方提供服务的过程进行直播、录播、回放和/或对录音录像拍照所取得的影像和声音记录进行编辑、改编、翻译、复制、展示或传播。乙方同意，直播以及录播回放录音录像和拍照内容可包括乙方的肖像和/或全名、教育背景、所服务的机构、职业经历等，并同意甲方或其指定的第三方有权以上述目的使用前述内容。在法律允许的范围内，甲方拥有与录音录像拍照所取得的影像和声音记录相关的所有版权和其他知识产权。
      </p>
      <p>
        1.12
        本活动由第三方提供资金支持，为了遵循资金支持方的合规管理要求，甲方需要在必要范围内向资金支持方提供乙方在本协议项下提供服务的支持材料（例如：讲课照片、音视频、服务条款与条件、付款凭证等），当中可能包含乙方的敏感个人信息。资金支持方应当依法保障乙方的个人信息权益，乙方可通过https://www.beigene.com/privacy-policy/cn/查阅资金支持方的个人信息处理规则。
      </p>
      <h4>2. 劳务及付款</h4>
      <p>2.1 乙方应提供之劳务的范围、劳务内容及计费标准见本协议《附件一》。</p>
      <p>2.2 甲方将劳务费以银行转账形式支付至下方乙方银行账户。</p>

      <h4>3. 协议的提前终止和终止后的影响</h4>
      <p>
        3.1
        如乙方违反本协议第1.1条和第1.2条约定，甲方有权经书面通知后立即提前终止本协议。
      </p>
      <p>
        3.2
        因本协议签署时依据的客观情况发生重大变化，致使本协议无法履行的，经任何一方书面通知后可提前终止本协议。
      </p>
      <p>
        3.3
        任何一方单方面解除本协议的，应当提前30日以书面方式通知另一方，经双方协商后终止本协议。
      </p>
      <p>
        3.4
        本协议提前终止后，乙方应当（1）向甲方指定的人员清晰交接与本协议下工作相关的事务；（2）乙方应立即停止于本协议下以甲方名义从事的活动。
      </p>
      <h4>4. 法律适用和争议解决</h4>
      <p>
        4.1 本协议的订立、效力、解释、履行、终止和争议的解决均适用中国法律。
      </p>
      <p>
        4.2
        因本协议而引起的或与本协议有关的一切争议，应当通过友好协商解决。如协商不成，任何一方可随时将该争议提交该方所在地有管辖权的法院通过诉讼途径解决。
      </p>
      <p>
        5.各方一致同意：对于（1）本协议，（2）各方之间的所有往来通讯，以及（3）一方向其他方提供的或从其他方收到的所有信息及其他材料（统称“保密信息”），除非披露保密信息的一方另行指示或法律另有规定，否则接收保密的各方应对保密信息应予以保密。
      </p>

      <p>
        6.
        乙方在任何情况下均不得出让、让与、转让或以其他方式转移其在本协议项下的任何权利或委任其在本协议项下的任何职责。此外，乙方亦不得向第三方分包或让与任何部分本协议项下的服务。当乙方获知其无法完成任何方面的服务时，应尽快告知甲方，并提交任何关于分包服务的书面请求，明确且详尽地描述需分包的服务内容，及接受乙方分包的第三方详情。尽管存在前述规定，乙方应始终对分包商的行为负责，并保证该等分包商遵守本协议条款。
      </p>
      <p>
        7.
        协议自甲方加盖公章且经乙方签字之日起生效，直至双方完全履行完毕其在本协议下的义务为止。
      </p>
      <p>8. 本协议一式两份，甲、乙双方各执一份，具有同等效力。</p>
      <h4>乙方确认：</h4>
      <p>
        本人确认按照《劳务协议》的要求如实、完整地提供了《劳务协议》附件一中所载明的各项劳务。
      </p>
      <div class="sign_group">
        <div class="sign_block">
          <div class="sign_block_content">
            <div class="sign_key">甲方：</div>
            <div class="sign_value">北京生命绿洲公益服务中心</div>
          </div>
          <div class="sign_block_content">
            {{ getText(0) }}&nbsp;年&nbsp;{{ getText(1) }}&nbsp;月&nbsp;{{
              getText(2)
            }}&nbsp;日
          </div>
        </div>
        <div class="sign_block">
          <div class="sign_block_content">
            <div class="sign_key">乙方：</div>
            <div class="sign_value">
              <el-button
                v-if="!data.sign_name && !isSigned"
                type="primary"
                size="small"
                @click.native="startSignName"
                >签名</el-button
              >
              <img
                v-if="data.sign_name"
                :src="data.sign_name"
                alt=""
                @load="uploadPage"
                @click="startSignName"
                crossorigin="anonymous"
              />
              <!-- <img  v-if='data.sign_name' :src="require('@/assets/images/signname.png')" alt="" @load='uploadPage' crossorigin='Anonymous'> -->
            </div>
          </div>
          <div class="sign_block_content">
            {{ getText(0) }}&nbsp;年&nbsp;{{ getText(1) }}&nbsp;月&nbsp;{{
              getText(2)
            }}&nbsp;日
          </div>
        </div>
      </div>

      <div class="agreement_btn_group" v-if="!isSigned && !isUpload">
        <el-button type="primary" @click.native="doCommit">确认提交</el-button>
      </div>
      <p class="agreement_main_seat"></p>
      <p class="agreement_main_seat"></p>

      <h4 style="text-align: center">《劳务协议》附件一</h4>
      <h4 style="text-align: center">
        【此处应以表格的方式列明乙方（1）提供服务的明细、（2）计价单位、（3）单位价格、（4）总价等信息】
      </h4>
      <p class="agreement_main_seat"></p>
      <h4>劳务费</h4>
      <div
        class="agreement_table"
        v-if="
          items.doctor.job_name == '主任医师' ||
          items.doctor.job_name == '副主任医师'
        "
      >
        <div class="agreement_row">
          <div class="agreement_td">角色</div>
          <div class="agreement_td">讲者</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">单价（人民币）</div>
          <div class="agreement_td">750元/人/场</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">具体工作内容</div>
          <div class="agreement_td">主导视频课件学习，并进行答疑互动</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">时长</div>
          <div class="agreement_td">问答环节大于10分钟</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">专家级别</div>
          <div class="agreement_td">副主任医师及以上</div>
        </div>
      </div>
      <p class="agreement_main_seat"></p>
      <div class="agreement_table" v-if="items.doctor.job_name == '主治医师'">
        <div class="agreement_row">
          <div class="agreement_td">角色</div>
          <div class="agreement_td">讲者</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">单价（人民币）</div>
          <div class="agreement_td">500元/人/场</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">具体工作内容</div>
          <div class="agreement_td">主导视频课件学习，并进行答疑互动</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">时长</div>
          <div class="agreement_td">问答环节大于10分钟</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">专家级别</div>
          <div class="agreement_td">主治级别</div>
        </div>
      </div>
      <p class="agreement_main_seat"></p>
      <div
        class="agreement_table"
        v-if="items.doctor.job_name == '医师(住院医师)'"
      >
        <div class="agreement_row">
          <div class="agreement_td">角色</div>
          <div class="agreement_td">讲者</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">单价（人民币）</div>
          <div class="agreement_td">250元/人/场</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">具体工作内容</div>
          <div class="agreement_td">主导视频课件学习，并进行答疑互动</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">时长</div>
          <div class="agreement_td">问答环节大于10分钟</div>
        </div>
        <div class="agreement_row">
          <div class="agreement_td">专家级别</div>
          <div class="agreement_td">住院医生</div>
        </div>
      </div>
    </div>
    <div class="sign_name_block" v-show="showStatement">
      <div class="sign_name_bg"></div>
      <div
        class="agreement_headimg_main"
        @click.stop="setStatement(false)"
        @touchmove.stop=""
      >
        <div class="agreement_headimg_block" @click.stop="">
          <h4 class="agreement_headimg_title">声 明</h4>
          <div class="agreement_headimg_content">
            <p class="agreement_summary">
              本人受北京生命绿洲公益服务中心委托，担任百展讲堂项目互动课堂主持。
            </p>
            <!-- <h4>一、 肖像权认定</h4> -->
            <p class="agreement_summary">
              在此，本人同意北京生命绿洲公益服务中心依据本协议约定可使用本人服务过程中的肖像等个人信息，在法律允许的范围内，北京生命绿洲公益服务中心拥有上述内容相关的所有版权和其他知识产权。
            </p>
            <p class="agreement_summary">本次签名只用于本协议。</p>
            <p class="agreement_summary">特此声明。</p>
          </div>
          <div class="agreement_headimg_btngroup">
            <div>
              <el-button
                type="info"
                size="small"
                @click.stop="setStatement(false)"
                round
                >不同意</el-button
              >
            </div>
            <div>
              <el-button
                type="primary"
                size="small"
                @click.stop="setStatement(true)"
                round
                >同意</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sign_name_block" v-show="!isSigned && showTips">
      <div class="sign_name_bg"></div>
      <div class="agreement_headimg_main">
        <div class="agreement_headimg_block" @click.stop="">
          <h4 class="agreement_headimg_title">提 示</h4>
          <div class="agreement_headimg_content">
            <p class="agreement_summary">
              请核对个人信息，并滑动到底部进行签名
            </p>
          </div>
          <div class="agreement_headimg_btngroup">
            <!-- <div>
                <el-button type="info" size='small' @click.stop='setStatement(false)' round>不同意</el-button>
            </div> -->
            <div>
              <el-button
                type="primary"
                size="small"
                @click.stop="showTips = false"
                round
                >同意</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sign_name_block" v-show="showSignName">
      <div class="sign_name_bg" @click="closeSignName"></div>
      <div class="agreement_autograph">
        <div class="agreement_autograph_back">
          <div class="agreement_autograph_text">签名</div>
          <div class="agreement_autograph_row agreement_autograph_row_line">
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
          </div>
          <div class="agreement_autograph_row agreement_autograph_row_line">
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
          </div>
          <div class="agreement_autograph_row">
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
            <div class="agreement_autograph_col"></div>
          </div>
          <div class="agreement_autograph_content">
            <canvas
              @touchstart.stop="touchstart"
              @touchmove.stop="touchmove"
              @touchend.stop="touchend"
              @touchcancel.stop="touchcancel"
              ref="canvas"
            ></canvas>
          </div>
        </div>
        <div class="agreement_btn_sign_group">
          <div>
            <el-button type="info" size="small" @click.stop="clearSignName"
              >清空</el-button
            >
            <el-button type="primary" size="small" @click.stop="takeSignName"
              >确认</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="agreement_loading_block" v-if="isUpload">
      <div class="agreement_loading_bg"></div>
      <div class="agreement_loading_content">
        <i class="el-icon-loading"></i>
        <p>协议正在保存中，请不要关闭页面</p>
      </div>
    </div>
  </div>
</template>

<script>
import BSscroll from "better-scroll";
import upload from "@/common/uploads";
export default {
  name: "agreement",
  data() {
    return {
      showStatement: false,
      showSignName: false,
      config: {},
      drawCanvas: {
        x: 0,
        y: 0,
      },
      canvas: null,
      context: null,
      isAgree: false,
      data: {
        meet_id: "",
        sign_name: "",
        img_page: "",
      },
      items: {
        doctor: {},
        meet: {},
      },
      showTips: true,
      isSigned: true,
      isUpload: false,
      isLoading: false,
      title: "劳务协议",
    };
  },
  created() {
    document.getElementsByTagName("title")[0].innerHTML = this.title;
    this.data.meet_id = this.$route.query.meet_id;
    this.curDate = this.$tools.getDate();
    // this.loadData();
    this.loadDoctor();
    this.loadMeet();
  },
  mounted() {
    this.initCanvas();
  },
  methods: {
    loadDoctor() {
      let url = this.$tools.getURL(this.$urls.doctor.doctorInfoByID, {
        id: this.$route.query.doctor_id,
      });
      this.$axios.get(url).then((res) => {
        this.items.doctor = res.data;
      });
    },
    loadMeet() {
      let url = this.$tools.getURL(this.$urls.admin.meeting, {
        meeting_id: this.$route.query.meet_id,
      });
      this.$axios
        .get(url)
        .then((res) => {
          if (res.errcode) {
            wxInfo.wx.closeWindow();
            return;
          }
          this.items.meet = res.data;
          this.data.sign_name = res.data.sign_name;
          this.isSigned = !!res.data.sign_name;
          this.curDate =
            res.data.sign_time ??
            this.$tools.getDate(this.items.meet.start_time);
          this.initScroll();
        })
        .catch(() => {
          this.initScroll();
        });
    },
    getText(index) {
      return this.curDate.split(/[\s-:]/g)[index] || "";
    },
    loadInfo() {},
    loadData() {
      let url = this.$tools.getURL(this.$urls.doctor.sign, {
        meet_id: this.data.meet_id,
      });
      this.$axios
        .get(url)
        .then((res) => {
          if (res.errcode) {
            // wxInfo.wx.closeWindow();
            return;
          }
          let data = res.data;
          this.items.doctor = res.doctor;
          this.items.meet = res.meet;
          if (data) {
            this.data.img_sign = data.img_sign;
            this.data.img_page = data.img_page;
            this.curDate = data.sign_date;
          }
          this.isSigned = !!res.data;
          this.initScroll();
        })
        .catch(() => {
          this.initScroll();
        });
    },
    initScroll() {
      console.log(2222);
      if (!this.scroll) {
        this.$nextTick(() => {
          this.scroll = new BSscroll(this.$refs.list, {
            click: true,
            bounce: false,
            preventDefault: false,
          });
        });
      } else {
        this.scroll.refresh();
      }
    },
    setStatement(status) {
      if (status == true) this.isAgree = true;
      this.showStatement = false;
      this.showSignName = status;
    },
    startSignName() {
      if (this.isSigned) return;
      if (this.isAgree) {
        // this.data.sign_name  = ""
        // this.clearSignName()
        this.showSignName = true;
        return;
      }
      this.showStatement = true;
    },
    closeSignName() {
      // this.clearSignName();
      this.showSignName = false;
    },
    clearSignName() {
      this.context.clearRect(
        0,
        0,
        this.canvas.width + this.canvas.offsetLeft,
        this.canvas.width + this.canvas.offsetTop
      );
    },
    async takeSignName() {
      this.isUpload = true;
      let file = this.canvas.toDataURL("image/png");
      let data = await upload.requestFile(
        file,
        "file",
        this.$urls.uploads.base64
      );
      this.data.sign_name = data.url;
      this.showSignName = false;
      // 暂时不要全图截屏
      this.isUpload = false;
    },
    async uploadPage() {
      // 暂时不要全图截屏
      return;
      if (this.isSigned) return;
      this.data.img_page = await upload.savePage(this.$refs.signPage);
      this.isUpload = false;
      // this.$tips.success({text:'保存成功'})
    },
    initCanvas() {
      this.$nextTick(() => {
        this.canvas = this.$refs.canvas;
        this.context = this.canvas.getContext("2d");
      });
    },
    touchstart(event) {
      let dom = event.touches[0];
      event.preventDefault();
      let position = this.canvas.getBoundingClientRect();
      this.drawCanvas = {
        x: dom.clientX - this.canvas.offsetLeft - position.left,
        y: dom.clientY - this.canvas.offsetTop - position.top,
      };
      this.scroll.disable();
    },
    touchmove(event) {
      event.preventDefault();
      if (this.start_time > Date.now() - 10) return;
      this.start_time = Date.now() + 10;
      let dom = event.touches[0];
      let position = this.canvas.getBoundingClientRect();
      let target = {
        x: dom.clientX - this.canvas.offsetLeft - position.left,
        y: dom.clientY - this.canvas.offsetTop - position.top,
      };
      // target = {
      //   x:target.x-(target.x-this.drawCanvas.x),
      //   y:target.y-(target.y-this.drawCanvas.y)
      // }
      this.$nextTick(() => {
        this.drawSignName(target);
        this.drawCanvas = { ...target };
      });
    },
    touchend(event) {
      this.initPoint();
      this.scroll.enable();
    },
    touchcancel(event) {
      this.initPoint();
      this.scroll.enable();
    },
    initPoint() {
      // this.drawCanvas = {
      //   x:0,
      //   y:0
      // }
    },
    drawSignName(target) {
      this.context.beginPath();
      this.context.globalAlpha = 1;
      this.context.lineWidth = 2;
      this.context.strokeStyle = "#000";
      let xRation = this.canvas.width / this.canvas.clientWidth;
      let yRation = this.canvas.height / this.canvas.clientHeight;
      this.context.moveTo(
        this.drawCanvas.x * xRation,
        this.drawCanvas.y * yRation
      );
      this.context.lineTo(target.x * xRation, target.y * yRation);
      this.context.closePath();
      this.context.stroke();
    },
    checkData() {
      if (this.data.sign_name == "") {
        this.$tips.error({ text: "请先签名" });
        return false;
      }
      // if(this.data.img_page == ''){
      //   // this.$tips.error({text:'协议正在保存中，请不要关闭页面'})
      //   return false;
      // }
      return true;
    },
    doCommit() {
      console.log("this.data :>> ", this.data);
      // console.log(this.isLoading, this.isSigned);
      if (!this.checkData() || this.isLoading || this.isSigned) return;
      this.isLoading = true;
      this.$axios
        .post(this.$urls.doctor.sign, this.data)
        .then((res) => {
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
          this.isSigned = true;
          wxInfo.wx.closeWindow();
        })
        .catch((res) => {
          this.data.sign_name = "";
          this.clearSignName();
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        });
    },
  },
};
</script>
<style>
.agreement_container {
  height: 100%;
  overflow: hidden;
  /* padding:0 5.6vw; */
  background: #fff;
}
.agreement_main {
  padding: 5.6vw;
}
.agreement_main h3 {
  text-align: center;
  padding-bottom: 4vw;
}
.agreement_title {
  font-size: 4.2vw;
  text-align: center;
  margin-bottom: 2.8vw;
}
.agreement_container p {
  font-size: 3.6vw;
  margin-bottom: 2.8vw;
  color: #333;
  line-height: 6.06vw;
}
.agreement_container h4 {
  margin: 4vw 0 2vw;
}
.agreement_summary {
  text-indent: 7.2vw;
  word-break: break-all;
}
.agreement_list {
  padding-left: 9vw;
}
.agreement_autograph {
  width: 100%;
  padding: 0 2vw;
  height: 81vw;
  display: flex;
  justify-content: center;
  background: #fff;
  flex-direction: column;
  position: relative;
  z-index: 2;
}
.agreement_autograph_back {
  width: 96vw;
  height: 62vw;
  border: 1px solid #111;
  position: relative;
}
.agreement_autograph_row {
  display: flex;
  box-sizing: border-box;
}
.agreement_autograph_row_line {
  border-bottom: 1px dashed #e9e9e9;
}
.agreement_autograph_col {
  height: 20vw;
  width: 25%;
  background: #fff;
  border-right: 1px dashed #e9e9e9;
}
.agreement_autograph_row > .agreement_autograph_col:last-child {
  border: none;
}

.agreement_btn_group {
  width: 100%;
  margin-top: 5vw;
}
.agreement_btn_group button {
  width: 100%;
}
.agreement_btn_sign_group {
  flex: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 999;
}
.agreement_btn_sign_group button {
  width: 24vw;
}
.agreement_autograph_text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30vw;
  color: #e6e6e6;
  opacity: 0.5;
  /* letter-spacing: 5vw; */
}
.agreement_main_line {
  display: inline-block;
  border-bottom: 1px solid #111;
  min-width: 40vw;
}
.agreement_main_line button {
  width: 20vw;
  height: 6vw;
  line-height: 6vw;
  padding-top: 0;
  padding-bottom: 0;
  margin: 1vw 0;
}
.agreement_main_line img {
  height: 6vw;
  width: auto;
}
.sign_name_block {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign_name_bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #f1f1f1;
  opacity: 0.5;
}
.sign_name_bg_content {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  height: 100%;
  width: 100%;
}
.agreement_autograph_content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agreement_headimg_main {
  position: relative;
  z-index: 100;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9vw;
}
.agreement_headimg_main .sign_name_bg {
  opacity: 0.8;
}
.agreement_headimg_block {
  background: #fff;
  height: auto;
  max-height: 120vw;
  border-radius: 2vw;
  padding: 0 8vw;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.agreement_headimg_content {
  flex: 1 0 0;
}
.agreement_headimg_btngroup {
  flex: 16vw 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agreement_headimg_btngroup div {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agreement_headimg_btngroup button {
  min-width: 20vw;
  /* width: 20vw; */
}
.agreement_headimg_title {
  flex: 10vw 0 0;
  line-height: 10vw;
  text-align: center;
}
.agreement_autograph_content img {
  width: 80%;
  height: auto;
}
.agreement_autograph_content canvas {
  width: 100%;
  height: 100%;
}
.sign_group {
  display: flex;
  height: 26vw;
}
.sign_block {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}
.sign_block:first-child {
  padding-right: 4vw;
}
.sign_block_content {
  flex: 1 0 0;
  display: flex;
  align-items: center;
}
.sign_value {
  flex: 1 0 0;
}
.sign_block_content button {
  width: 24vw;
}
.sign_block_content img {
  height: 12vw;
  width: auto;
}
.agreement_bottom_line {
  text-decoration: underline;
}
.agreement_space_row {
  display: flex;
  padding-right: 4vw;
}
.agreement_space_row span:first-child {
  /* flex:22vw 0 0; */
}
.agreement_space_row span:last-child {
  flex: 1 0 0;
  border-bottom: 1px solid #000;
}
.agreement_loading_block,
.agreement_loading_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.agreement_loading_block {
  display: flex;
  align-items: center;
  justify-content: center;
}
.agreement_loading_block i {
}
.agreement_loading_content {
  position: relative;
  z-index: 2;
  color: #fff;
  text-align: center;
  font-size: 15vw;
  z-index: 99999;
}
.agreement_loading_content p {
  font-size: 5vw;
  color: #fff;
}
.agreement_loading_bg {
  z-index: 1;
  background: gray;
  opacity: 0.7;
}
.agreement_table {
  width: 100%;
  height: 100%;
  border: 1px solid #000;
}
.agreement_row {
  display: flex;
  min-height: 6vw;
}
.agreement_td {
  padding: 1vw 2vw;
  line-height: 6vw;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  word-break: break-all;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.agreement_td div {
  line-height: 4.8vw;
}
.agreement_row .agreement_td:first-child {
  flex: 35vw 0 0;
}
.agreement_row .agreement_td:last-child {
  flex: 1 0 0;
  border-right: 0;
  text-decoration: underline;
}
.agreement_row:last-child > .agreement_td {
  border-bottom: 0;
}
.agreement_main_bold {
  font-weight: bold;
}
.agreement_main_secondary {
  text-indent: 1em;
}
.agreement_main_seat {
  height: 4vw;
}
</style>